var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", [
    _c(
      "div",
      [
        _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
        _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
        _c("div", { staticClass: "md-reply" }, [
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.contentType == 1 ||
                    _vm.contentType == 2 ||
                    _vm.contentType == 4,
                  expression:
                    "contentType == 1 || contentType == 2 || contentType == 4",
                },
              ],
              staticClass: "reply-title",
            },
            [_vm._v(" 댓글 ")]
          ),
          _c(
            "div",
            { staticClass: "reply-list" },
            [
              _c("div", { staticClass: "reply-write" }, [
                _c("div", { staticClass: "textarea-wrap" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.commentText,
                        expression: "commentText",
                        modifiers: { trim: true },
                      },
                    ],
                    staticStyle: { resize: "none" },
                    attrs: {
                      placeholder:
                        "주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다.",
                    },
                    domProps: { value: _vm.commentText },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.checkedLogin($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.commentText = $event.target.value.trim()
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn-registration",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.register($event)
                      },
                    },
                  },
                  [_vm._v(" 등록 ")]
                ),
              ]),
              _vm._l(_vm.commentlist, function (item, index) {
                return [
                  item.modifyComment
                    ? _c(
                        "div",
                        {
                          key: item.commentId,
                          staticClass: "replay-item reply-edit",
                        },
                        [
                          _c("p", { staticClass: "info" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.userName)),
                            ]),
                            !item.isWithDrawUser && !item.isAdmin
                              ? _c("span", { staticClass: "class" }, [
                                  _vm._v(_vm._s(item.hcpMajorName)),
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    item.modifyDate,
                                    "YYYY. MM. DD"
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    item.modifyDate,
                                    "HH:mm"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "re-reply-write edit-type" },
                            [
                              _c("div", { staticClass: "textarea-wrap" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: item.commentText,
                                      expression: "item.commentText",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  attrs: {
                                    placeholder:
                                      "주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다.",
                                  },
                                  domProps: { value: item.commentText },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        item,
                                        "commentText",
                                        $event.target.value.trim()
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "flex-r btn-edit" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-s-registration",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.updateComment(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 수정 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-cancel",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.modifyComment(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 취소 ")]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          key: item.commentId,
                          staticClass: "replay-item my-write",
                        },
                        [
                          _c("p", { staticClass: "info" }, [
                            _c(
                              "span",
                              {
                                class: item.isWithDrawUser
                                  ? "name withdraw"
                                  : "name",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.isWithDrawUser
                                      ? "존재하지 않는 회원입니다."
                                      : item.userName
                                  )
                                ),
                              ]
                            ),
                            !item.isWithDrawUser && !item.isAdmin
                              ? _c("span", { staticClass: "class" }, [
                                  _vm._v(_vm._s(item.hcpMajorName)),
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "date" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    item.modifyDate,
                                    "YYYY. MM. DD"
                                  )
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "time" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    item.modifyDate,
                                    "HH:mm"
                                  )
                                )
                              ),
                            ]),
                          ]),
                          item.isDelete
                            ? _c("p", { staticClass: "txt delete-type" }, [
                                _c("i", { staticClass: "ico-delete" }),
                                _vm._v("삭제된 댓글입니다. "),
                              ])
                            : _c(
                                "p",
                                { staticClass: "txt" },
                                [
                                  _vm._l(
                                    _vm.splitMessages(item.commentText),
                                    function (msg, mIdx) {
                                      return [
                                        _vm._v(" " + _vm._s(msg) + " "),
                                        _c("br", { key: "comment_" + mIdx }),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                          _c("div", { staticClass: "btn-all" }, [
                            !item.isDelete
                              ? _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.showReply(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 답글 쓰기 ")]
                                )
                              : _vm._e(),
                            item.isEdit && !item.isDelete
                              ? _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.modifyComment(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 수정 ")]
                                )
                              : _vm._e(),
                            item.isEdit && !item.isDelete
                              ? _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeComment(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" 삭제 ")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.replyWrite,
                          expression: "item.replyWrite",
                        },
                      ],
                      key: index,
                      staticClass: "replay-item re-reply edit-type",
                    },
                    [
                      _c("div", { staticClass: "re-reply-write" }, [
                        _c("div", { staticClass: "textarea-wrap" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: item.replyComment,
                                expression: "item.replyComment",
                                modifiers: { trim: true },
                              },
                            ],
                            staticStyle: { resize: "none" },
                            attrs: {
                              placeholder:
                                "주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다.",
                            },
                            domProps: { value: item.replyComment },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  item,
                                  "replyComment",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "flex-r btn-edit" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn-s-registration",
                              on: {
                                click: function ($event) {
                                  return _vm.registerReply(item)
                                },
                              },
                            },
                            [_vm._v(" 등록 ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn-cancel",
                              on: {
                                click: function ($event) {
                                  return _vm.cancelReply(item)
                                },
                              },
                            },
                            [_vm._v(" 취소 ")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._l(item.childrenComments, function (reply) {
                    return _c("div", { key: reply.commentId }, [
                      reply.modifyReply
                        ? _c("div", { staticClass: "replay-item re-reply" }, [
                            _c("p", { staticClass: "info" }, [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(reply.userName)),
                              ]),
                              _c("span", { staticClass: "class" }, [
                                _vm._v(_vm._s(reply.hcpMajorName)),
                              ]),
                              _c("span", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      reply.modifyDate,
                                      "YYYY. MM. DD"
                                    )
                                  )
                                ),
                              ]),
                              _c("span", { staticClass: "time" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      reply.modifyDate,
                                      "HH:mm"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "re-reply-write" }, [
                              _c("div", { staticClass: "textarea-wrap" }, [
                                _c(
                                  "textarea",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: reply.commentText,
                                        expression: "reply.commentText",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "active",
                                    attrs: {
                                      placeholder:
                                        "주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다.",
                                    },
                                    domProps: { value: reply.commentText },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          reply,
                                          "commentText",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  },
                                  [_vm._v("좋은 컨텐츠 감사합니다")]
                                ),
                              ]),
                              _c("div", { staticClass: "flex-r btn-edit" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-s-registration",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateComment(reply)
                                      },
                                    },
                                  },
                                  [_vm._v(" 수정 ")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-cancel",
                                    on: {
                                      click: function ($event) {
                                        return _vm.modifyReply(reply)
                                      },
                                    },
                                  },
                                  [_vm._v(" 취소 ")]
                                ),
                              ]),
                            ]),
                          ])
                        : _c("div", { staticClass: "replay-item re-reply" }, [
                            _c("p", { staticClass: "info" }, [
                              _c(
                                "span",
                                {
                                  class: reply.isWithDrawUser
                                    ? "name withdraw"
                                    : "name",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      reply.isWithDrawUser
                                        ? "존재하지 않는 회원입니다."
                                        : reply.userName
                                    )
                                  ),
                                ]
                              ),
                              !reply.isWithDrawUser && !reply.isAdmin
                                ? _c("span", { staticClass: "class" }, [
                                    _vm._v(_vm._s(reply.hcpMajorName)),
                                  ])
                                : _vm._e(),
                              _c("span", { staticClass: "date" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      reply.modifyDate,
                                      "YYYY. MM. DD"
                                    )
                                  )
                                ),
                              ]),
                              _c("span", { staticClass: "time" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("$dateFormatter")(
                                      reply.modifyDate,
                                      "HH:mm"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                            reply.isDelete
                              ? _c("p", { staticClass: "txt delete-type" }, [
                                  _c("i", { staticClass: "ico-delete" }),
                                  _vm._v("삭제된 답글입니다. "),
                                ])
                              : _c(
                                  "p",
                                  { staticClass: "txt" },
                                  [
                                    _vm._l(
                                      _vm.splitMessages(reply.commentText),
                                      function (msg, mIdx) {
                                        return [
                                          _vm._v(" " + _vm._s(msg) + " "),
                                          _c("br", { key: "comment_" + mIdx }),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                            _c("div", { staticClass: "btn-all" }, [
                              reply.isEdit && !reply.isDelete
                                ? _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.modifyReply(reply)
                                        },
                                      },
                                    },
                                    [_vm._v(" 수정 ")]
                                  )
                                : _vm._e(),
                              reply.isEdit && !reply.isDelete
                                ? _c(
                                    "button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.removeComment(reply)
                                        },
                                      },
                                    },
                                    [_vm._v(" 삭제 ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                    ])
                  }),
                ]
              }),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }