<!-- eslint-disable vue/require-v-for-key -->
<template>
  <article>
    <div>
      <alert-dialog :options="alertProps" />
      <confirm-dialog :options="confirmProps" />
      <div class="md-reply">
        <p
          v-show="contentType == 1 || contentType == 2 || contentType == 4"
          class="reply-title"
        >
          댓글
        </p>
        <div class="reply-list">
          <div class="reply-write">
            <div class="textarea-wrap">
              <textarea
                v-model.trim="commentText"
                style="resize: none"
                placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                @click.stop="checkedLogin"
              />
            </div>
            <button
              class="btn-registration"
              @click.stop="register"
            >
              등록
            </button>
          </div>

          <template
            v-for="(item, index) in commentlist"
          >
            <div
              v-if="item.modifyComment"
              :key="item.commentId"
              class="replay-item reply-edit"
            >
              <p class="info">
                <span class="name">{{ item.userName }}</span>
                <span
                  v-if="!item.isWithDrawUser && !item.isAdmin"
                  class="class"
                >{{ item.hcpMajorName }}</span>
                <span class="date">{{ item.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                <span class="time">{{ item.modifyDate | $dateFormatter('HH:mm') }}</span>
              </p>

              <div class="re-reply-write edit-type">
                <div class="textarea-wrap">
                  <textarea
                    v-model.trim="item.commentText"
                    placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                  />
                </div>
                <div class="flex-r btn-edit">
                  <button
                    class="btn-s-registration"
                    @click.stop="updateComment(item)"
                  >
                    수정
                  </button>
                  <button
                    class="btn-cancel"
                    @click.stop="modifyComment(item)"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>

            <div
              v-else
              :key="item.commentId"
              class="replay-item my-write"
            >
              <p class="info">
                <span :class="item.isWithDrawUser ? 'name withdraw' : 'name'">{{ item.isWithDrawUser ? '존재하지 않는 회원입니다.' : item.userName }}</span>
                <span
                  v-if="!item.isWithDrawUser && !item.isAdmin"
                  class="class"
                >{{ item.hcpMajorName }}</span>
                <span class="date">{{ item.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                <span class="time">{{ item.modifyDate | $dateFormatter('HH:mm') }}</span>
              </p>

              <p
                v-if="item.isDelete"
                class="txt delete-type"
              >
                <i class="ico-delete" />삭제된 댓글입니다.
              </p>

              <p
                v-else
                class="txt"
              >
                <template v-for="(msg, mIdx) in splitMessages(item.commentText)">
                  {{ msg }} <br :key="`comment_${mIdx}`">
                </template>
              </p>

              <div class="btn-all">
                <button
                  v-if="!item.isDelete"
                  @click="showReply(item)"
                >
                  답글 쓰기
                </button>
                <button
                  v-if="item.isEdit && !item.isDelete"
                  @click="modifyComment(item)"
                >
                  수정
                </button>
                <button
                  v-if="item.isEdit && !item.isDelete"
                  @click="removeComment(item)"
                >
                  삭제
                </button>
              </div>
            </div>

            <div
              v-show="item.replyWrite"
              :key="index"
              class="replay-item re-reply edit-type"
            >
              <div class="re-reply-write">
                <div class="textarea-wrap">
                  <textarea
                    v-model.trim="item.replyComment"
                    style="resize: none"
                    placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                  />
                </div>
                <div class="flex-r btn-edit">
                  <button
                    class="btn-s-registration"
                    @click="registerReply(item)"
                  >
                    등록
                  </button>
                  <button
                    class="btn-cancel"
                    @click="cancelReply(item)"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>

            <div
              v-for="reply in item.childrenComments"
              :key="reply.commentId"
            >
              <div
                v-if="reply.modifyReply"
                class="replay-item re-reply"
              >
                <p class="info">
                  <span class="name">{{ reply.userName }}</span>
                  <span class="class">{{ reply.hcpMajorName }}</span>
                  <span class="date">{{ reply.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                  <span class="time">{{ reply.modifyDate | $dateFormatter('HH:mm') }}</span>
                </p>

                <div class="re-reply-write">
                  <div class="textarea-wrap">
                    <textarea
                      v-model.trim="reply.commentText"
                      class="active"
                      placeholder="주제와 무관한 댓글, 타인의 권리를 침해하거나 명예를 훼손하는 댓글은 별도의 통보 없이 삭제될 수 있습니다."
                    >좋은 컨텐츠 감사합니다</textarea>
                  </div>
                  <div class="flex-r btn-edit">
                    <button
                      class="btn-s-registration"
                      @click="updateComment(reply)"
                    >
                      수정
                    </button>
                    <button
                      class="btn-cancel"
                      @click="modifyReply(reply)"
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="replay-item re-reply"
              >
                <p class="info">
                  <span :class="reply.isWithDrawUser ? 'name withdraw' : 'name'">{{ reply.isWithDrawUser ? '존재하지 않는 회원입니다.' : reply.userName }}</span>
                  <span
                    v-if="!reply.isWithDrawUser && !reply.isAdmin"
                    class="class"
                  >{{ reply.hcpMajorName }}</span>
                  <span class="date">{{ reply.modifyDate | $dateFormatter('YYYY. MM. DD') }}</span>
                  <span class="time">{{ reply.modifyDate | $dateFormatter('HH:mm') }}</span>
                </p>
                <p
                  v-if="reply.isDelete"
                  class="txt delete-type"
                >
                  <i class="ico-delete" />삭제된 답글입니다.
                </p>

                <p
                  v-else
                  class="txt"
                >
                  <template v-for="(msg, mIdx) in splitMessages(reply.commentText)">
                    {{ msg }} <br :key="`comment_${mIdx}`">
                  </template>
                </p>

                <div class="btn-all">
                  <button
                    v-if="reply.isEdit && !reply.isDelete"
                    @click="modifyReply(reply)"
                  >
                    수정
                  </button>
                  <button
                    v-if="reply.isEdit && !reply.isDelete"
                    @click.prevent="removeComment(reply)"
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import { getUserLoginInfo, confirmPointMission } from '@/common/userInfo/service'
import { setStatistics } from '@/common/statistics/service'

export default {
  props: {
    contentType: {
      type: Number,
      required: true,
    },
    isHook: {
      type: Boolean,
      default: false,
    },
    memberOnly: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      contentId: 0,
      commentlist: [],
      commentText: null,
      isProcessing: false,
    }
  },

  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'infoData/getUserName',
      medicalDept: 'infoData/getMedicalDept',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    })
  },

  watch: {
    shaEnLicenseNum() {
      if (this.join === null) getUserLoginInfo()
    },
  },

  created() {
    // 회원여부 조회
    if (this.join === null) getUserLoginInfo()
  },

  mounted() {
  },

  methods: {
    splitMessages(message) {
      return message.split('\n')
    },

    fetchComment(id, contentType) {
      axios.get(`/fu/comment/${id}`, {
        params: {
          contentType,
          userHash: this.shaEnLicenseNum
        },
      })
        .then((rs) => {
          this.contentId = id
          this.contentType = contentType

          this.commentlist = rs.data
        })
        .catch(() => {})
    },
    async checkedLogin() {
      let isLoggedIn = false

      if (this.memberOnly || (this.memberOnly === false && this.join)) {
        // 회원만 댓글 허용(default) 또는 비회원 허용이면서 회원인 경우에는 로그인 필수
        isLoggedIn = await isUserLoggedIn()
      } else {
        // 비회원 허용이면서 비회원인 경우
        isLoggedIn = true
      }

      if (!isLoggedIn) {
        this.$router.push({
          name: 'login-main',
          query: { redirect: this.$route.name },
        })
      }
    },
    modifyComment(item) {
      this.$set(item, 'modifyComment', !item.modifyComment)
    },
    showReply(item) {
      this.checkedLogin()
      this.$set(item, 'replyWrite', !item.replyWrite)
    },
    cancelReply(item) {
      this.$set(item, 'replyWrite', false)
      this.$set(item, 'replyEditText', '')
    },
    modifyReply(item) {
      this.$set(item, 'modifyReply', !item.modifyReply)
    },
    register() {
      if (this.isProcessing) return

      this.checkedLogin()

      if (this.commentText == null || this.commentText.length === 0) {
        this.showAlertDialog('댓글을 입력해주세요.')
        return
      }

      if (this.isHook) {
        // 호출자에서 등록 전처리가 필요한 경우 실행하지 않고 넘김
        this.$emit('hook', { call: 'createComment' })
        return
      }

      // 댓글 저장
      this.createComment()
    },
    createComment() {
      this.isProcessing = true

      axios.post(`/fu/comment`, {
        contentId: this.contentId,
        contentType: this.contentType,
        commentText: this.commentText,
        userName: this.userName,
        medicalDept: this.medicalDept,
        userHash: this.shaEnLicenseNum
      })
        .then(rs => {
          this.commentText = null
          this.isProcessing = false

          this.fetchComment(this.contentId, this.contentType)

          if (this.contentType === 2) {
            confirmPointMission(this.contentType === 1 ? 12 : 13)
            setStatistics(this.contentType === 1 ? 126 : 146, this.contentId, rs.data)
          }
        })
        .catch(() => {
          this.isProcessing = false
          this.showAlertDialog('댓글 작성 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
    registerReply(item) {
      if (this.isProcessing) {
        return
      }

      if (item.replyComment == null || item.replyComment.length === 0) {
        this.showAlertDialog('답글을 입력해주세요.')
        return
      }

      if (this.isHook) {
        // 호출자에서 등록 전처리가 필요한 경우 실행하지 않고 넘김
        this.$emit('hook', {
          call: 'createReply',
          parentCommentId: item.commentId,
          replyComment: item.replyComment
        })
        return
      }

      // 답글 저장
      this.createReply(item.commentId, item.replyComment)
    },
    createReply(parentCommentId, replyComment) {
      this.isProcessing = true

      axios.post(`/fu/comment`, {
        contentId: this.contentId,
        contentType: this.contentType,
        commentText: replyComment,
        parentId: parentCommentId,
        userName: this.userName,
        medicalDept: this.medicalDept,
        userHash: this.shaEnLicenseNum
      })
        .then(() => {
          this.commentText = null
          this.isProcessing = false

          this.fetchComment(this.contentId, this.contentType)

          if (this.contentType === 1 || this.contentType === 2) confirmPointMission(this.contentType === 1 ? 12 : 13)
        })
        .catch(() => {
          this.isProcessing = false
          this.showAlertDialog('답글 작성 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
    updateComment(item) {
      if (item.commentText === null || item.commentText.length === 0) {
        this.showAlertDialog('댓글을 입력해주세요.')
        return
      }
      axios.put(`/fu/comment`, {
        commentId: item.commentId,
        commentText: item.commentText
      })
        .then(() => {
          this.fetchComment(this.contentId, this.contentType)
        })
        .catch(() => {
          this.showAlertDialog('댓글 수정 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
    removeComment(item) {
      this.showConfirmDialog('해당 댓글을 삭제하시겠습니까?', (result) => {
        if (result) {
          if (this.isHook) {
            // 호출자에서 등록 전처리가 필요한 경우 실행하지 않고 넘김
            this.$emit('hook', {
              call: 'removeComment',
              commentId: item.commentId
            })
            return
          }

          // 댓글 삭제
          this.deleteComment(item.commentId)
        }
      })
    },
    deleteComment(commentId) {
      axios.delete(`/fu/comment/delete/${commentId}`)
        .then(() => {
          this.fetchComment(this.contentId, this.contentType)
        })
        .catch(() => {
          this.showAlertDialog('댓글 삭제 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
  },
}
</script>
